.users-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include tablet {
    gap: 16px;
  }
}

.users-navigation__item {
  display: flex;

  &--wide {
    width: 100%;
    justify-content: flex-end;
    transform: translateY(8px);
  }

  @include tablet {
    &--wide {
      transform: none;
    }
  }
}
