.quote-card {
  margin: 0;
  background-color: $base-white;
  border-radius: 8px;

  @include tablet {
    flex-grow: 1;
  }
}

.quote-card__link {
  text-decoration: none;
  float: right;
  border-radius: 0 8px 0 8px;
  background-color: $base-gray;
  color: $base-white;
  font-size: 14px;
  line-height: 1;
  padding: 4px 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.quote-card__top {
  padding: 8px 16px;

  @include tablet {
    padding: 16px 24px;
  }
}

.quote-card__quote {
  quotes: none;
  display: block;
  margin-bottom: 8px;
  white-space: pre-wrap;
}

.quote-card__tags {
  font-size: 14px;
  color: $primary-light;
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;
}

.quote-card__tag {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.3s;
  display: inline-block;

  .tags-hidden &:nth-child(n+4) {
    visibility: hidden;
    display: none;
  }

  &--current {
    font-weight: bold;
  }

  @include desktop {
    &:hover {
      color: $base-secondary;
    }
  }
}

.quote-card__bottom {
  position: relative;
  border-top: 1px solid $base-gray;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @include tablet {
    padding: 8px 24px;
    gap: 24px;
  }
}

.quote-card__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  color: $base-gray;
}

.quote-card__button {
  margin-left: auto;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    fill: none;
  }

  &--toggle-tags {
    color: $base-secondary;

    &::before {
      content: attr(data-hide-text);
    }

    .tags-hidden &::before {
      content: attr(data-show-text);
    }
  }

  &--favorite {
    color: $base-secondary;

    svg {
      fill: $base-secondary
    }
  }
}

.quote-card__button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.quote-card__share {
  position: relative;
  z-index: 1;
  max-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.quote-card__share-links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  right: calc(100%);
  background-color: $base-white;
  display: flex;
  gap: 8px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  .quote-card__share:hover & {
    visibility: visible;
    opacity: 1;
  }
}

.quote-card__share-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: $base-secondary;
  padding: 0;
  border-radius: 4px;
  border: none;
  color: $base-white;
  cursor: pointer;
}

.quote-card__share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  color: $base-gray;

  .quote-card__share:hover & {
    color: $base-primary;
  }
}
