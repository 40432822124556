.favorite-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.favorite-list__item {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: max-content;

  &--child {
    margin-left: 32px;
  }
}

.favorite-list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid $base-primary;
  color: transparent;

  input:checked+& {
    color: white;
    border: 1px solid $base-primary;
    background-color: #66bb6a;
  }
}
