@import '_quote-card.scss';
@import '_pagination.scss';

.quotes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quotes__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include tablet {
    gap: 16px;
  }
}
