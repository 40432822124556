*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  line-height: inherit;
}

img {
  max-width: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;

  &--secondary {
    color: $base-secondary;
  }
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 40px;
  min-height: 40px;
  max-width: max-content;
  text-decoration: none;
  border: none;
  background-color: $base-primary;
  color: $base-white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: 0.3s;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
  }

  &[data-loading] {
    opacity: 0.6;
    pointer-events: none;
    color: transparent;
    background-image: url("/images/spinner.gif");
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
  }

  &--secondary {
    background-color: $base-secondary;
  }

  &--gray {
    background-color: #B2B2B2;
  }

  @include desktop {
    &:hover {
      background-color: $base-secondary;
    }
  }
}

.text {
  margin-top: 0;
  margin-bottom: 0;
  color: $base-black;
  line-height: 24px;
  font-size: 16px;

  &--error {
    color: #DC0000;
  }

  &--success {
    color: #66bb6a;
  }
}

.box {
  margin-top: 8px;

  p {
    margin: 0;
  }
  ul {
    margin: 0;
  }
}
