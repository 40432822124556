$base-black: #000000;
$base-white: #ffffff;
$base-primary: #111111;
$primary-light: #747474;
$base-secondary: #e2b65c;
$base-gray: #b2b2b2;
$gray-light: #f4f4f4;

$tablet-width: 768px;
$desktop-width: 1200px
