.user-page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    flex-direction: row-reverse;
  }
}

.user-page__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    flex-grow: 1;
  }
}
