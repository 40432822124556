.page {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  font-size: 16px;
  line-height: 1.4;
  color: $base-primary;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $base-secondary;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $base-primary;
  }
}

.page__body {
  min-height: 100%;
  min-width: 320px;
  margin: 0;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  align-content: start;
  gap: 16px;
  background-color: $gray-light;

  @include tablet {
    gap: 24px;
  }
}
